import axios from "@/utils/request";

//获取楼凤数据API
export function getLouFengList(data) {
  return axios.get(`/loufeng/list`, {
    params: data
  });
}

// 获取楼凤具体信息
export function getLoufengInfo(data) {
  return axios.post(`/loufeng/detail`, data)
}

// 获取体验楼凤详情
export function getLoufengExperDetails(data) {
  return axios.post(`/sexsrv/expreport/bygirl/list`, data)
}

// 是否收藏楼凤
export function updateLike(data) {
  return axios.post(`/loufeng/like`, data)
}

// 获取搜索楼凤列表
export function getSearchList(data) {
  return axios.post(`/loufeng/search`, data);
}

// 获取楼凤城市列表
export function getCityList(data) {
  return axios.post(`/loufeng/cities`, data);
}

// 获取楼凤经理人的妹子
export function getLoufengAgentList(data) {
  return axios.get(`/loufeng/byagent/list`, {
    params: data
  });
}

// 关注与取消
export function editUserCare(data) {
  return axios.post(`/care/user`, data);
}

// 上传图片
export function uploadImg(data) {
  return axios({
    url: `/upload/img`,
    method: 'post',
    headers:{
      'Content-Type':'multipart/form-data'
    },
    data,
    noEncrpty: true,
  })
}

// 添加楼凤报告
export function addExperReport(data) {
  return axios.post(`/sexsrv/expreport/add`, data);
}

// 添加楼凤举报
export function addReport(data) {
  return axios.post(`/sexsrv/feedback/add`, data);
}

// 获取优惠券(楼凤)
export function getCoupons(data) {
  return axios.get(`/backpack/coupon`, {
    params: data
  });
}

// 购买楼凤信息
export function buyLouFeng(data) {
  return axios.post(`/loufeng/buy`, data);
}

// 预约楼凤
export function bookLouFeng(data) {
  return axios.post(`/loufeng/book`, data);
}

/*********************** AI脱衣 ******************/
//生成AI脱衣图片
export function generateImg(data) {
  return axios.post("/ai/undress/generate", data)
}

//获取AI脱衣记录列表
export function getAiRecord(data) {
  return axios.get("/ai/undress/list", { params: data })
}
