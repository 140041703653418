<template>
  <div class="aiUndress">
    <div class="navBar">
      <div @click="$router.go(-1)">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">AI脱衣</div>
      <div class="rightBtn" @click="$router.push('/aiRecord')">生成记录</div>
    </div>
    <div class="main">
      <div class="cover" v-if="coverImg">
        <ImgDecypt :src="coverImg" class="cover" />
      </div>
      <van-uploader
        v-else
        v-model="fileList"
        :after-read="afterRead"
        :preview-image="false"
      >
        <div class="uploadBox"></div>
      </van-uploader>
      <div class="precautions">注意事项：</div>
      <p>1.照片只含一名人物</p>
      <p>2.照片不能过暗</p>
      <p>3.照片尽量清晰</p>
      <p>4.不支持多人照片禁止未成年人照片</p>
      <div class="generateBox"></div>
      <div class="bottom">
        <div class="priceBox">
          <div class="coinOnce">{{ aiUndressPrice || 0 }}金币一次</div>
        </div>
        <div class="submit" @click="getGenerateImg">提交</div>
      </div>
    </div>
    <!-- 会员购买弹窗-->
    <VipBuy
      class="buy_parent"
      :show="isVipbuyShow"
      @setVipGrap="setVipGrap"
      @close="isVipbuyShow = false"
    />
  </div>
</template>
<script>
import { Toast, Uploader } from "vant";
import { uploadImg, generateImg } from "@/api/loufeng.js";
import ImgDecypt from "@/components/ImgDecypt";
import { mapGetters } from "vuex";
import VipBuy from "@/components/VipBuy";

export default {
  components: {
    ImgDecypt,
    VipBuy,
    [Uploader.name]: Uploader,
  },
  computed: {
    ...mapGetters({
      aiUndressPrice: "aiUndressPrice",
    }),
  },
  data() {
    return {
      fileList: [],
      coverImg: "",
      generateCoverImg: "",
      isVipbuyShow: false,
      userInfo: "",
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log(this.userInfo);
  },
  methods: {
    // 图片上传
    async afterRead(file) {
      if (file.length) {
        file.map(async (item) => {
          let newFile = await this.compress(item.file);
          item.file = newFile;
          await this.uplpadImg(item);
        });
      } else {
        let newFile = await this.compress(file.file);
        file.file = newFile;
        this.uplpadImg(file);
      }
    },
    //图片上传
    async uplpadImg(fileInfo) {
      let req = new FormData();
      req.append("file", fileInfo.file);
      fileInfo.status = "uploading";
      fileInfo.message = "上传中...";
      try {
        let ret = await this.$Api(uploadImg, req);
        if (ret.code == 200) {
          fileInfo.status = "";
          fileInfo.message = "";
          this.coverImg = ret.data.path;
          return;
        }
        fileInfo.status = "failed";
        fileInfo.message = "上传失败";
      } catch (e) {
        fileInfo.status = "failed";
        fileInfo.message = "上传失败";
      }
    },
    // 压缩图片
    compress(file) {
      let selt = this;
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onloadend = function () {
            let result = reader.result;
            let img = new Image();
            img.src = result;
            if (result.length <= 200 * 1024) {
              let blob = selt.convertBase64ToBlob(result);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
              return;
            }
            img.onload = function () {
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");
              let width = img.width;
              let height = img.height;
              canvas.width = width;
              canvas.height = height;
              // 铺底色
              ctx.fillStyle = "#fff";
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, width, height);
              //进行最小压缩
              let ndata = canvas.toDataURL("image/jpeg", 0.3);
              let blob = selt.convertBase64ToBlob(ndata);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
            };
          };
        });
      }
    },
    //将base64转换为文件对象
    convertBase64ToBlob(base64) {
      var base64Arr = base64.split(",");
      var imgtype = "";
      var base64String = "";
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(
          base64Arr[0].indexOf(":") + 1,
          base64Arr[0].indexOf(";")
        );
      }
      // 将base64解码
      var bytes = atob(base64String);
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode);

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }
      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], { type: imgtype });
    },
    // 获取脱衣后的图片
    async getGenerateImg() {
      if (!this.userInfo.vipType != 0) return (this.isVipbuyShow = true);
      if (!this.coverImg) return Toast("请先上传一张图片");
      // if (Number(this.aiUndressPrice) > this.userInfo.balance) return Toast('账户余额不足');
      let req = {
        originPic: this.coverImg,
        coin: Number(this.aiUndressPrice),
      };
      let res = await this.$Api(generateImg, req);
      if (res && res.code == 200) {
        Toast("上传成功，等待审核");
        this.coverImg = "";
        // this.userInfo.balance = this.userInfo.balance - this.aiUndressPrice;
      } else {
        Toast(res.tip || "上传失败");
      }
    },
    setVipGrap() {
      let vipGrap = {
        type: "video",
        vInfo: this.videoInfo,
      };
      sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
    },
  },
};
</script>
<style lang="scss" scoped>
.aiUndress {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    color: white;
    background-color: #f21313;
    .leftBtn {
      width: 22px;
      height: 22px;
      padding-right: 18px;
    }
    .title {
      font-size: 20px;
    }
    .rightBtn {
      width: 60px;
      font-size: 14px;
    }
  }
  .main {
    padding: 22px 11px 11px;
    height: calc(100vh - 44px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    .uploadBox {
      height: 178px;
      width: 352px;
      border-radius: 6px;
      background: url("./../../../assets/png/aiUploadBg.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
    }
    .cover {
      height: 178px;
      width: 352px;
      border-radius: 6px;
      overflow: hidden;
      margin: 0 auto;
    }
    .precautions {
      font-size: 16px;
      color: rgb(102, 102, 102);
      margin-top: 18px;
    }
    p {
      font-size: 14px;
      color: rgba(49, 48, 48, 0.5);
      line-height: 20px;
    }
    .generateBox {
      height: 167px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 18px auto 0;
      background: url("./../../../assets/png/caseDiagram.png") no-repeat;
      background-size: 100% 100%;
    }
    .bottom {
      margin-top: 50px;
      margin-bottom: 80px;
      padding: 0 20px;
      .priceBox {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
      }
      .submit {
        height: 40px;
        background-color: rgb(238, 44, 61);
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        color: white;
        border-radius: 40px;
        margin-top: 16px;
      }
    }
  }
  /deep/ .van-uploader {
    width: 100%;
    .van-uploader__wrapper {
      width: 100%;
      .van-uploader__input-wrapper {
        width: 100%;
      }
    }
  }
  .buy_parent {
    /deep/ .van-overlay {
      position: absolute;
    }
  }
}
</style>
